'use strict';

import Util from '../_util';

hideClickHere(10000);

$('#two-auth-click-here').on('click', () => {
    hideClickHere(30000);
    Util.ajax({
        method: 'POST',
        data: {
            login: $('#login_login').val(),
            password: $('#login_password').val(),
            isFirstMail: JSON.stringify(false),
        },
        url: '/admin/user/request-disconnected-mail', /*TODO: FOSJsRoutingBundle*/
    }, {});
});

/**
 * @param {int} duration 
 */
function hideClickHere(duration) {
    $('#click-here-container').fadeOut({
        duration: 200,
    });
    setTimeout(() => {
        $('#click-here-container').fadeIn({
            duration: 500,
        });
    }, duration);
}

import '../_password';

require('../../scss/login.scss');
require('../../scss/password.scss');